import request from '../utils/request';
// ---------- 购买相关 -------
//班级添加购物车（整体报名）--true
export function doShoppingCartZG(data) {
  return request({
    url: '/order/doShoppingCartZG.do',
    method: 'post',
    data
  });
}

//选择课程加入购物车（非整体报名）--true
export function doShoppingCart(data) {
  return request({
    url: '/order/doShoppingCart.do',
    method: 'post',
    data
  });
}

//选择学习课程（非整体报名）--true
export function toSelectLeaningCourse(data) {
  return request({
    url: '/order/toSelectLeaningCourse.do',
    method: 'post',
    data
  });
}

//非整体报名添加购物车前置判断（非整体报名）--true
export function toNoOrderAjax(data) {
  return request({
    url: '/order/toNoOrderAjax.do',
    method: 'post',
    data
  });
}

//查看购物车
export function toShoppingCart() {
  return request({
    url: '/order/toShoppingCart.do',
    method: 'get'
  });
}

//清空
export function doDelShoppingCart() {
  return request({
    url: '/order/doDelShoppingCart.do',
    method: 'get'
  });
}

//删除选中购物车
export function doDelShoppingCartByClass(data) {
  return request({
    url: '/order/doDelShoppingCartByClass.do',
    method: 'post',
    data
  });
}

//购物车课程查看（非整体报名）--true
export function toCourseCk(data) {
  return request({
    url: '/order/toCourseCk.do',
    method: 'post',
    data
  });
}


//删除课程
export function doDelShoppingCartByCourse(data) {
  return request({
    url: '/order/doDelShoppingCartByCourse.do',
    method: 'post',
    data
  });
}


//支付宝跳转
export function toReturnUrl(data) {
  return request({
    url: '/order/toReturnUrl.html',
    method: 'get',
    params:data
  });
}

//运单支付宝跳转
export function toReturnEOrderExpressUrl(data) {
  return request({
    url: '/order/toReturnEOrderExpressUrl.html',
    method: 'get',
    params:data
  });
}
