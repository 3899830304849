<template>
  <div style="position: absolute;top: 0;width: 100%;height: 100%;background: #F0F2F5;"
       ref="contentData">
    <pcHeaders></pcHeaders>
    <div style="display: flex;justify-content: center;background: #fff;width: 100%;height: 100px;align-items: center;">
      <img style="width: 1000px;height: 80px;"
           src="../../assets/images/lc01.png"
           alt="">
    </div>
    <div class="cen">
      <div class="w">
        <div class="headBox">
          <div class="leftBox">
            <img v-if="classInfo.classImage && classInfo.classImage !==null && classInfo.classImage !== ''"
                 style="width: 253px;height: 206px;"
                 :src="classInfo.classImage"
                 alt=""
                 @error="handleError">

            <div v-else
                 style="position: relative;display: flex;justify-content: center;align-items: center;width: 253px;height: 206px;">
              <img style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;;"
                   src="../../assets/indexImg/noimg.png"
                   alt="">
              <div style="position: relative;z-index: 11;font-size: 15px;font-family: kaiti;text-align: center;padding: 0 10px;font-weight: bold;color: #355FD9;">
                {{ classInfo.className}}
              </div>
            </div>

          </div>
          <div class="rightBox">
            <div style="font-size: 18px;">{{ classInfo.className }}</div>

            <div class="mt10"
                 style="color: #FF6C4C;">{{ classInfo.lx }} &emsp; <span v-if="classInfo.isClassApply == '02'"> ￥{{ classInfo.classPrice.toFixed(1) }}</span> <span v-else> ￥{{ classInfo.creditPrice }}/学时
              </span>&emsp; {{ classInfo.classCredithour }}学时</div>
            <div class="mt10">招生人数 : {{ classInfo.classPeopleNumber  }}人&emsp; 培训方式：{{ classInfo.classType == '01' ? '在线' : classInfo.classType == '02' ? '面授' :'混合'}} </div>
            <div class="mt10 textOver"
                 :title="classInfo.classDesc">班级介绍 :{{ classInfo.classDesc }}</div>
            <div class="mt10 textOver"
                 :title="classInfo.classDemo">报名须知 :{{ classInfo.classDemo }}</div>
            <div class="mt10">是否考试 :{{ classInfo.isExam == '02' ? '需要考试' :'不需要考试' }} </div>
            <div class="mt10">报名时间:{{ classInfo.classEnrolStarttime }} - {{ classInfo.classEnrolEndtime }} 上课时间:{{ classInfo.classStarttime }} - {{ classInfo.classEndtime }}</div>
            <div class="goumBtn"
                 v-if="classInfo.classEnrolStatus == '02' && classInfo.timeFlag == '02'"
                 @click="purchase">购买</div>

            <div class="mt10"
                 style="color: #FF9D50;">根据安徽省人社厅《皖人社秘〔2019〕157号》文件精神，自2019年起，在申报职称的最近一个任职周期内，年度继续教育学时平均达到规定学时即可，但不得在一个年度内突击完成所需学时。请广大学员咨询当地人社局并根据自己实际情况，选择相应学时学习。</div>
          </div>
        </div>

        <div class="examList"
             v-if="classIndependentQuiz !== undefined && classIndependentQuiz.length">
          <div class="examHead">
            <div><img style="margin-top: 5px"
                   src="../../assets/images/02_03.png"
                   alt=""></div>
            <div style="margin-left: 5px;">独立考试列表</div>
          </div>

          <el-table :data="classIndependentQuiz"
                    style="width: 100%;margin-top: 15px;">
            <el-table-column prop="date"
                             label="考试封面"
                             width="180"
                             align="center">

              <template slot-scope="scope">
                <img v-if="scope.row.quizImage !== '' && scope.row.quizImage !== null"
                     style="width: 150px;height: 100px;"
                     :src="scope.row.quizImage"
                     @error="handleError"
                     alt="">
                <div v-else
                     style="position: relative;display: flex;justify-content: center;align-items: center;width: 150px;height: 100px;">
                  <img style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;;"
                       src="../../assets/indexImg/noimg.png"
                       alt="">
                  <div style="position: relative;z-index: 11;font-size: 15px;font-family: kaiti;text-align: center;padding: 0 10px;font-weight: bold;color: #355FD9;">
                    {{ scope.row.name }}
                  </div>
                </div>
              </template>

            </el-table-column>
            <el-table-column prop="name"
                             label="考试名称"
                             align="center">
            </el-table-column>
            <el-table-column label="总分/及格分数"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.totalPoint }} / {{scope.row.passscore }}
              </template>
            </el-table-column>
            <el-table-column prop="credit"
                             label="学时"
                             align="center">
            </el-table-column>
            <el-table-column prop="timelimit"
                             label="考试时长"
                             align="center">

              <template slot-scope="scope">
                {{ scope.row.timelimit }} 分钟
              </template>
            </el-table-column>
            <el-table-column label="考试时间"
                             width="180"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.timeopen}} - {{ scope.row.timeclose}}
              </template>
            </el-table-column>
            <el-table-column prop="price"
                             label="价格"
                             align="center">
              <template slot-scope="scope">
                <span style="color: rgb(255, 157, 80);">￥{{ scope.row.price}}</span>
              </template>
            </el-table-column>

            <el-table-column label="操作"
                             align="center">
              <template slot-scope="scope">
                <div style="display: flex;justify-content: center;">
                  <div style="color: #FF9F53;cursor: pointer;"
                       @click="ruleShow(scope.row)">考试规则</div>

                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="examList">
          <div class="examHead">
            <div><img style="margin-top: 5px"
                   src="../../assets/images/02_03.png"
                   alt=""></div>
            <div style="margin-left: 5px;">课程列表</div>
          </div>

          <el-table :data="courseList"
                    style="width: 100%;margin-top: 15px;">
            <el-table-column prop="C_IMAGE"
                             label="课程封面"
                             width="280"
                             align="center">
              <template slot-scope="scope">

               <div style="display: flex;justify-content: center;">
                <img v-if="scope.row.C_IMAGE !== '' && scope.row.C_IMAGE !== null"
                     style="width: 150px;height: 100px;"
                     :src="scope.row.C_IMAGE"
                     @click="tableHandel(scope.row)"
                     @error="handleError"
                     alt="">

                <div v-else
                     @click="tableHandel(scope.row)"
                     style="position: relative;display: flex;justify-content: center;align-items: center;width: 150px;height: 100px;">
                  <img style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;;"
                       src="../../assets/indexImg/noimg.png"
                       alt="">
                  <div style="position: relative;z-index: 11;font-size: 15px;font-family: kaiti;text-align: center;padding: 0 10px;font-weight: bold;color: #355FD9;">
                    {{ scope.row.C_NAME }}
                  </div>
                </div>
               </div>
              </template>
            </el-table-column>
            <el-table-column prop="C_NAME"
                             label="课程名称"
                             width="220"
                             align="center">
              <template slot-scope="scope">
                <span style="color: #4077C0;cursor: pointer;"
                      @click="tableHandel(scope.row)">{{ scope.row.C_NAME }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="C_CREDITHOUR"
                             label="学时"
                             align="center">
            </el-table-column>
            <el-table-column prop="C_PRICE"
                             label="价格"
                             align="center">
              <template slot-scope="scope">

                <span style="color: rgb(255, 157, 80);"
                      v-if="classInfo.isClassApply == '01' || classInfo.isClassApply == null || classInfo.isClassApply == '' ">￥{{ classInfo.creditPrice * scope.row.C_CREDITHOUR }}</span>
                <span style="color: rgb(255, 157, 80);"
                      v-if="classInfo.isClassApply == '02'">￥{{ scope.row.C_PRICE }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="address"
                             label="操作"
                             align="center">
              <template slot-scope="scope">
                <div style="display: flex;justify-content: center;">
                  <div v-if="classInfo.classEnrolStatus == '02' && classInfo.timeFlag == '02'"
                       style="color: #b93b3b;cursor: pointer;border: 1px solid #396FF0;color: #396FF0;border-radius: 100px;padding: 5px 20px;"
                       @click="purchase">购买</div>
                  <div style="margin-left: 5px;cursor: pointer;color: #4077C0;background-color:  #396FF0;color: #fff;border-radius: 100px;padding: 5px 20px;"
                       @click="tableHandel(scope.row)">试听</div>
                </div>
              </template>
            </el-table-column>
          </el-table>

        </div>
        <pagination v-show="total>0"
                    :total="total"
                    :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize"
                    @pagination="getClassCourseList" />
        <div class="examList">
          <div class="examHead">
            <div><img style="margin-top: 5px"
                   src="../../assets/images/02_03.png"
                   alt=""></div>
            <div style="margin-left: 5px;">热门推荐</div>
          </div>

          <div class="tui">
            <div class="tuiList"
                 v-for="item in hotClassList"
                 :key="item.ID"
                 @click="classDetail(item)">
              <div>
                <img v-if="item.CLASS_IMAGE !== '' && item.CLASS_IMAGE !== null"
                     :src="item.CLASS_IMAGE"
                     @error="handleError"
                     alt="">

                <div v-else
                     style="position: relative;display: flex;justify-content: center;align-items: center;width: 170px;height: 135px;">
                  <img style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;;"
                       src="../../assets/indexImg/noimg.png"
                       alt="">
                  <div style="position: relative;z-index: 11;font-size: 15px;font-family: kaiti;text-align: center;padding: 0 10px;font-weight: bold;color: #355FD9;">
                    {{ item.CLASS_NAME }}
                  </div>
                </div>
              </div>

              <div class="btmBox">
                <div class="btmText"
                     :title="item.CLASS_NAME">{{ item.CLASS_NAME }}</div>
                <div style="display: flex;justify-content: space-between;">
                  <div style="color: #ED1100;"><span style="font-size: 22px;">{{ item.CLASS_CREDITHOUR }}</span>学时</div>
                  <div style="color: #ED1100;">￥{{ item.CLASS_PRICE }}</div>
                </div>
                <div style="display: flex;justify-content: space-between;position: absolute;bottom: 0;right: 5px;">
                  <div>

                  </div>
                  <div style="display: flex;">
                    <div>
                      <img style="width: 22px;height: 14px;"
                           src="../../assets/images/gw-06.png"
                           alt="">
                    </div>
                    <div>
                      购买
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <el-dialog title="考试规则"
               :visible.sync="dialogVisible"
               width="70%">
      <el-table :data="rule"
                style="width: 100%;margin-top: 15px;">
        <el-table-column prop="name"
                         label="考试名称"
                         align="center">
        </el-table-column>
        <el-table-column label="总分"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.totalPoint }} 分
          </template>
        </el-table-column>
        <el-table-column label="及格分数"
                         align="center">
          <template slot-scope="scope">
            {{scope.row.passscore }} 分
          </template>
        </el-table-column>
        <el-table-column prop="credit"
                         label="学时"
                         align="center">
        </el-table-column>
        <el-table-column prop="timelimit"
                         label="考试时长"
                         align="center">

          <template slot-scope="scope">
            {{ scope.row.timelimit }} 分钟
          </template>
        </el-table-column>
        <el-table-column label="考试时间"
                         width="180"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.timeopen}} - {{ scope.row.timeclose}}
          </template>
        </el-table-column>
        <el-table-column prop="maxallowed"
                         label="最大答题次数"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.maxallowed == 0 ? '不限制' : scope.row.maxallowed}}
          </template>
        </el-table-column>
        <el-table-column prop="attemptonlast"
                         label="继续答题"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.attemptonlast == '01' ? '不允许' : '允许'}}
          </template>
        </el-table-column>
        <el-table-column prop="grademethod"
                         label="评分办法"
                         align="center">

          <template slot-scope="scope">
            {{ scope.row.grademethod == '01' ? '最新' : scope.row.grademethod == '02' ? '最高': '平均'}}
          </template>
        </el-table-column>
        <el-table-column prop="description"
                         label="考试须知"
                         align="center"
                         width="280">
        </el-table-column>
      </el-table>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>

      </span>
    </el-dialog>
    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import { getClassDetail, getClassCourseList, getHotClassList, findClassIndependentQuiz } from '@/api/lsitFs.js';
import { doShoppingCartZG } from '@/api/car.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      classId: '',
      classInfo: {}, //班级数据
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      courseList: [], //课程列表
      hotClassList: [], //热门列表
      classIndependentQuiz: [], //独立考试
      dialogVisible: false,
      defaultImageUrl: require('../../assets/images/noimg.png'),
      rule: {} //考试规则
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    handleError(event) {
      event.target.src = this.defaultImageUrl;
    },
    //购买
    purchase() {
      console.log(this.classInfo);
      //非整体报名
      if (this.classInfo.isClassApply !== '02') {
        this.$router.push({
          path: '/purchase',
          query: {
            id: this.classInfo.id
          }
        });
      } else {
        //整体报名直接加入购物车
        doShoppingCartZG({ classId: this.classInfo.id }).then(res => {
          if (res.data.data.code == '1') {
            this.$message({
              message: '添加购物车成功！',
              type: 'success'
            });
            this.$router.push('/car');
          } else {
            this.$message({
              message: res.data.data.msg,
              type: 'warning'
            });
          }
        });
        return false;
      }
    },
    //点击表格
    tableHandel(row) {
      console.log(row);
      this.$router.push({
        path: '/courseDetail',
        query: {
          courseId: row.ID,
          classId: this.classInfo.id,
          timeFlag: this.classInfo.timeFlag
        }
      });
    },
    //考试规则
    ruleShow(row) {
      this.rule = [row];
      console.log(this.rule);
      this.dialogVisible = true;
    },
    //独立考试列表
    findClassIndependentQuiz() {
      findClassIndependentQuiz({ classId: this.queryParams.classId }).then(res => {
        this.classIndependentQuiz = res.data.data;
        console.log(this.classIndependentQuiz);
      });
    },
    //热门班级
    classDetail(item) {
      this.queryParams.classId = item.ID;
      console.log(this.queryParams.classId);
      this.getClassDetail();
      this.getClassCourseList();
    },
    getClassDetail() {
      getClassDetail({ classId: this.queryParams.classId }).then(res => {
        console.log(res.data.data);
        this.classInfo = res.data.data;
      });
    },
    getClassCourseList() {
      getClassCourseList(this.queryParams).then(res => {
        this.total = res.data.total;
        this.courseList = res.data.rows;
        window.scrollTo(0, 100);
        // 设置滚动行为改为平滑的滚动
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    getHotClassList() {
      getHotClassList().then(res => {
        console.log(res.data);
        this.hotClassList = res.data.data;
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.queryParams.classId = this.$route.query.classId;
    this.getClassDetail();
    this.getClassCourseList();
    this.getHotClassList();
    this.findClassIndependentQuiz();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.cen {
  font-family: Regular;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  background: #f0f2f5;
  .w {
    width: 1200px;
    padding-top: 30px;
    background: #f0f2f5;
    .headBox {
      background: #fff;
      padding: 30px 20px 10px 20px;
      border: 1px solid #f2f2f3;
      overflow: hidden;
      position: relative;
      display: flex;
      .rightBox {
        margin-left: 25px;
        font-size: 12px;
        .mt10 {
          margin-top: 10px;
        }
        .textOver {
          width: 800px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        .goumBtn {
          width: 150px;
          height: 31px;
          text-align: center;
          line-height: 31px;
          background: #ff9f53;
          color: #fff;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          cursor: pointer;
          margin-left: 10px;
          margin-right: 10px;
          margin-top: 10px;
        }
      }
    }
    .examList {
      margin-top: 25px;
      .examHead {
        display: flex;
        font-size: 20px;
      }
      .tui {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding: 30px 20px;
        background: #fff;
        border: 1px solid #f2f2f3;
        .tuiList {
          cursor: pointer;
          width: 170px;
          height: 200px;
          position: relative;
          -webkit-box-shadow: 3px 3px 3px #e7e7e7;
          -moz-box-shadow: 3px 3px 3px #e7e7e7;
          box-shadow: 3px 3px 3px #e7e7e7;
          img {
            width: 170px;
            height: 135px;
            margin-bottom: 5px;
          }
          .btmBox {
            padding: 0 10px;
            .btmText {
              width: 150px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>